import AuthPage from "../components/AuthPage";
import { useAuth } from "@/services/hooks/useAuth";

export default function LoginPage(props) {
    const { loginWithEmail } = useAuth();
    return (
        <AuthPage
            labels={{
                NAME: "Vacayou Voyager Club Log in",
                SUBMIT_BUTTON_TEXT: "Log in",
                REDIRECT_PROMPT: `Don't have a Vacayou Voyager Club account?`,
                REDIRECT_PROMPT_LINK_TEXT: "Join now!",
                REDIRECT_PROMPT_LINK_URL: "/join",
                DISPLAY_FORGOT_PASSWORD: true,
            }}
            onSubmit={(email, password) => {
                return loginWithEmail(email, password)
                    .then((userCredential) => {
                        return userCredential;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }}
        />
    );
}
